<template>
  <div class="">

    <div class="blog-pages-wrapper section-space--ptb_100 border-bottom">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <div class="main-blog-wrap">
              <!--======= Single Blog Item Start ========-->
              <div class="single-blog-item">
                <!-- Post info Start -->
                <div class="post-info lg-blog-post-info  wow move-up">
                  <h4 class="post-title font-weight--light">
                    Regras de Privacidade
                  </h4>
                  <div class="post-excerpt mt-15">
                    <p>
                      A Educatena tem como premissa básica garantir a
                      privacidade de seus usuários e, por meio da presente
                      regra, visa garantir a transparência das atividades
                      desenvolvidas pela Educatena e dos serviços prestados por
                      meio da nossa plataforma (sites ou aplicativos).
                    </p>
                    <h5 class="post-title font-weight--light">
                      I. Quais informações utilizamos
                    </h5>
                    <p>
                      A Educatena trata dados pessoais de quem tem ou teve algum
                      relacionamento com a empresa, foi ou é representante,
                      procurador, empregado, preposto, sócio de empresa ou
                      entidade participante das nossas diversas atividades, de
                      pessoas com as quais a Educatena pretende se relacionar ou
                      com outros titulares de dados pessoais (“usuário”).
                    </p>
                    <p>
                      A Educatena poderá coletar as informações inseridas
                      ativamente por você, usuário, no momento do acesso,
                      contato ou cadastro e, ainda, informações coletadas
                      automaticamente de você, ao utilizar os nossos serviços,
                      como, por exemplo, dados atrelados à sua conexão, tais
                      como: endereço de IP, data, hora, dispositivo utilizado,
                      dados de consumo e hábitos de utilização, entre outras
                      informações:
                    </p>
                    <p>
                      (a) <b>Dados pessoais fornecidos pelo usuário:</b> a
                      Educatena coleta todos os dados pessoais inseridos ou
                      encaminhados ativamente pelo usuário ao se cadastrar em
                      nossos sites ou aplicativos, necessários – em especial,
                      mas não se limitando, para a inscrição em exames, cursos,
                      concursos, eventos, cadastramento em nossos sistemas
                      públicos ou internos para seus funcionários e/ou
                      colaboradores, participar de nossos fóruns, grupos de
                      trabalho, se cadastrar, acessar participar ou receber
                      informações relacionadas aos nossos serviços. São eles,
                      exemplificadamente, mas não se limitando a dados
                      cadastrais: nacionalidade, nome, sobrenome, data de
                      nascimento, gênero, CPF, país, CEP, endereço (número,
                      bairro, complemento), cidade e estado, telefone, e-mail.
                    </p>
                    <p>
                      Independentemente de quais dados pessoais o usuário
                      fornece ativamente à Educatena, nós apenas faremos uso
                      daqueles efetivamente relevantes e necessários para o
                      alcance das finalidades a ele declaradas caso a caso.
                    </p>
                    <p>
                      (b) <b>Dados coletados automaticamente:</b> a Educatena
                      também coleta uma série de informações de modo automático,
                      tais como: características do dispositivo de acesso, do
                      navegador, IP (com data e hora), origem do IP, informações
                      sobre cliques, páginas acessadas, termos de procura
                      digitados em nossos portais, entre outros. Para tal
                      coleta, a Educatena fará uso de algumas tecnologias
                      comuns, como cookies, pixel tags, beacons e local shared
                      objects, entre outras, que são utilizadas com o propósito
                      de melhorar a sua experiência de navegação em nossos
                      serviços, de acordo com os seus hábitos e as suas
                      preferências.
                    </p>
                    <p>
                      Além disso, os dados pessoais podem ser coletados pela
                      Educatena de fontes externas, como fornecedores ou
                      prestadores de serviços da Educatena e fontes públicas,
                      como internet, mídias sociais, meios de comunicação em
                      geral, órgãos públicos, reguladores, registros públicos.
                    </p>
                    <p>
                      A Educatena também poderá gerar dados decorrentes do
                      relacionamento do usuário com a Instituição de Ensino ou
                      em decorrência da utilização de produtos e serviços
                      prestados ou oferecidos pela Educatena.
                    </p>
                    <p>
                      A Educatena poderá, ainda, tratar, para as finalidades
                      gerais aqui dispostas, dados definidos como sensíveis pela
                      Lei 13.709/19, entendidos como aqueles relacionados à
                      origem racial ou étnica, convicção religiosa, opinião,
                      regra, filiação a sindicato ou a organização de caráter
                      religioso, filosófico ou político, dado referente à saúde
                      ou à vida sexual, dado genético ou biométrico, ou dados de
                      crianças, assim considerados os indivíduos menores de 12
                      anos, sendo certo que, na remota hipótese em que for
                      necessário o tratamento dessas informações, solicitaremos
                      o consentimento específico e destacado do usuário ou dos
                      pais e responsáveis deste, ou realizaremos o tratamento
                      dessas informações com base nas demais hipóteses
                      autorizadoras trazidas pela Lei Federal 13.709/19.
                    </p>
                    <h5 class="post-title font-weight--light">
                      II. Como utilizamos os dados
                    </h5>
                    <p>
                      Os dados pessoais tratados pela Educatena têm como
                      principais finalidades o estabelecimento de vínculo
                      contratual, para a prestação dos serviços em
                      certificações, supervisão de instituições de ensino,
                      recursos humanos, cadastro, participação em grupo de
                      estudos, fóruns, reuniões e cursos, identificar os
                      usuários que navegam no site, aplicativos e/ou entram em
                      contato pelos canais de atendimento, bem como atualizar os
                      cadastros dos usuários para fins de contato por telefone,
                      correio eletrônico e/ou SMS, sistemas disponibilizados
                      pela Educatena ao público em geral, a seus funcionários
                      e/ou colaboradores.
                    </p>
                    <p>
                      A Educatena poderá utilizar as suas informações em demais
                      serviços prestados diretamente por ela, respeitadas as
                      finalidades expostas a você e a autorização que você
                      previamente nos concedeu, quando houver a exigência legal
                      de coleta desta.
                    </p>
                    <p>
                      A Educatena , em alguns casos, também pode tratar dados
                      pessoais quando necessário para o cumprimento de obrigação
                      legal ou regulatória ou exercício regular de direitos em
                      processo judicial, administrativo ou arbitral.
                    </p>
                    <p>
                      Além disso, a Educatena poderá tratar dados pessoais com
                      base em seu interesse legítimo, sempre no limite de sua
                      expectativa, e nunca em prejuízo de seus interesses,
                      direitos e liberdades fundamentais.
                    </p>
                    <p>
                      Adicionalmente, as informações coletadas poderão, mediante
                      a coleta prévia de sua autorização, serem utilizadas para
                      fins publicitários, como para o envio de comunicações,
                      notícias que sejam de seu interesse, bem como divulgação
                      de eventos, cursos e certificações, ou para pesquisas
                      relacionadas às atividades da Educatena.
                    </p>
                    <p>
                      Caso você se sinta incomodado e não deseje mais receber
                      quaisquer informativos publicitários da Educatena ,
                      poderá, a qualquer momento, nos contatar por meio do
                      formulário disponível em na página da Educatena na
                      internet
                      <a
                        href="https://educatena.com.br"
                        class="hover-style-link"
                        >https://educatena.com.br</a
                      >
                      manifestando a sua oposição.
                    </p>
                    <h5 class="post-title font-weight--light">
                      III. O que é e como utilizamos a tecnologia de cookies
                    </h5>
                    <p>
                      Cookies são arquivos ou informações que podem ser
                      armazenadas em seus dispositivos quando você visita os
                      websites ou utiliza os serviços online da Educatena.
                      Geralmente, um cookie contém o nome do site que o
                      originou, seu tempo de vida e um valor gerado
                      aleatoriamente.
                    </p>
                    <p>
                      A Educatena utiliza cookies para facilitar o uso e melhor
                      adaptar seu website e aplicações aos interesses e
                      necessidades de seus usuários, bem como para auxiliar e
                      melhorar suas estruturas e seus conteúdos. Os cookies
                      também podem ser utilizados para acelerar suas atividades
                      e experiências futuras em nossos serviços.
                    </p>
                    <p>
                      Por meio de cookies, o site armazena informações sobre as
                      atividades do navegador, incluindo endereço IP e a página
                      acessada. Esses registros de atividades (logs), que
                      poderão compreender dados como o endereço IP do usuário,
                      as ações efetuadas pelos usuários no site, as páginas
                      acessadas pelo usuário, as datas e os horários de cada
                      ação e de acesso a cada página do site e as informações
                      sobre o dispositivo utilizado, versão de sistema
                      operacional, navegador, dentre outros aplicativos
                      instalados, serão utilizados apenas para fins estatísticos
                      e de métricas dos serviços disponibilizados ou para a
                      investigação de fraudes ou de alterações indevidas em seus
                      sistemas e cadastros, não tendo como finalidade o
                      fornecimento dos dados a terceiros sem autorização
                      expressa do usuário.
                    </p>
                    <p>
                      A qualquer momento você, usuário de nossos serviços,
                      poderá revogar a sua autorização quanto aos cookies,
                      utilizando, para tanto, as configurações de seu navegador
                      de preferência; contudo, alertamos que, de acordo com a
                      escolha realizada, certas funcionalidades dos nossos
                      serviços poderão não trabalhar da maneira idealmente
                      esperada.
                    </p>
                    <h5 class="post-title font-weight--light">
                      IV. Com quem compartilharemos os dados
                    </h5>
                    <p>
                      A Educatena utiliza-se, para a operacionalização de seus
                      serviços, da parceria com diversas empresas localizadas no
                      Brasil e no exterior. Deste modo, a Educatena poderá
                      compartilhar as suas informações pessoais, nas hipóteses a
                      seguir expostas:
                    </p>
                    <p class="paragraph-forwards">
                      i. Com empresas parceiras e fornecedores, no
                      desenvolvimento e prestação de serviços voltados a você.
                    </p>
                    <p class="paragraph-forwards">
                      ii. Com autoridades, entidades governamentais ou outros
                      terceiros, para a proteção dos interesses da Educatena em
                      qualquer tipo de conflito, incluindo ações judiciais e
                      processos administrativos.
                    </p>
                    <p class="paragraph-forwards">
                      iii. No caso de transações e alterações societárias
                      envolvendo a Educatena, hipótese em que a transferência
                      das informações será necessária para a continuidade dos
                      serviços.
                    </p>
                    <p class="paragraph-forwards">
                      iv. Mediante ordem judicial ou pelo requerimento de
                      autoridades administrativas que detenham competência legal
                      para a sua requisição.
                    </p>
                    <p>
                      Adicionalmente, é possível que algumas das transferências
                      indicadas acima fora do território brasileiro. A Educatena
                      se compromete a fazê-lo somente para países que
                      proporcionem um grau de proteção aos seus dados pessoais
                      considerado como adequado ao previsto na legislação
                      aplicável ou mediante a adoção de garantias e
                      salvaguardas, como cláusulas específicas,
                      cláusulas-padrão, normas corporativas globais, entre
                      outras; bem como mediante a prévia coleta do seu
                      consentimento específico ou a observância das demais
                      hipóteses autorizadas por lei.
                    </p>
                    <h5 class="post-title font-weight--light">
                      V. Como mantemos os seus dados pessoais seguros
                    </h5>
                    <p>
                      Qualquer informação fornecida pelo usuário será coletada e
                      guardada de acordo com os mais rígidos padrões de
                      segurança.
                    </p>
                    <p>
                      Para tanto, adota diversas precauções, em observância às
                      diretrizes sobre padrões de segurança estabelecidas nas
                      legislações e regulamentações aplicáveis, tais como:
                    </p>
                    <p class="paragraph-forwards">
                      1. A Educatena utiliza os mais recentes métodos e
                      equipamentos disponíveis no mercado para criptografar e
                      anonimizar os seus dados pessoais, quando necessário.
                    </p>
                    <p class="paragraph-forwards">
                      2. A Educatena possui proteção contra acesso não
                      autorizado a seus sistemas.
                    </p>
                    <p class="paragraph-forwards">
                      3. A Educatena somente autoriza o acesso de pessoas
                      específicas ao local onde são armazenadas as suas
                      informações pessoais, desde que este seja essencial ao
                      desenvolvimento da atividade pretendida.
                    </p>
                    <p class="paragraph-forwards">
                      4. A Educatena garante que aqueles agentes, funcionários
                      internos ou parceiros externos que realizarem o tratamento
                      de dados pessoais deverão se comprometer a manter o sigilo
                      absoluto das informações acessadas, bem como de adotar as
                      melhores práticas para manuseio destas informações,
                      conforme determinado nas regras e procedimentos externos.
                    </p>
                    <p>
                      Além dos esforços técnicos, a Educatena também adota
                      medidas institucionais visando à proteção de dados
                      pessoais, de modo que mantém programa de governança em
                      privacidade aplicado a suas atividades e estrutura de
                      governança, constantemente atualizado.
                    </p>
                    <p>
                      O acesso às informações coletadas é restrito a
                      colaboradores e pessoas autorizadas. Aqueles que se
                      utilizarem indevidamente dessas informações, em violação
                      desta Regra de Privacidade e Segurança da Informação,
                      estarão sujeitos a sanções administrativas, disciplinares
                      e legais cabíveis.
                    </p>
                    <p>
                      Embora a Educatena adote os melhores esforços no sentido
                      de preservar a sua privacidade e proteger os seus dados
                      pessoais, nenhuma transmissão de informações é totalmente
                      segura, de modo que a Educatena não pode garantir
                      integralmente que todas as informações que recebe e/ou
                      envia não sejam alvo de acessos não autorizados
                      perpetrados por meio de métodos desenvolvidos para obter
                      informações de forma indevida, como falhas técnicas, vírus
                      ou invasões do banco de dados do site. De qualquer forma,
                      na remota hipótese de incidência de episódios desta
                      natureza, a Educatena garante o pleno esforço para
                      remediar as consequências do evento.
                    </p>
                    <h5 class="post-title font-weight--light">
                      VI. Retenção das informações coletadas
                    </h5>
                    <p>
                      Visando proteger a privacidade de seus usuários, os dados
                      pessoais tratados pela Educatena serão automaticamente
                      eliminados quando deixarem de ser úteis aos fins para os
                      quais foram coletados, ou quando o usuário solicitar a sua
                      eliminação, exceto se a manutenção do dado for
                      expressamente autorizada por lei ou regulação aplicável.
                    </p>
                    <p>
                      As informações dos usuários poderão ser conservadas para
                      cumprimento de obrigação legal ou regulatória,
                      transferência a terceiro – desde que respeitados os
                      requisitos de tratamento de dados – e uso exclusivo da
                      Educatena, inclusive para o exercício de seus direitos em
                      processos judiciais ou administrativos.
                    </p>
                    <h5 class="post-title font-weight--light">
                      VII. Os direitos dos usuários
                    </h5>
                    <p>
                      Em cumprimento à regulamentação aplicável, no que diz
                      respeito ao tratamento de dados pessoais, a Educatena
                      respeita e garante ao usuário a possibilidade de
                      apresentar solicitações baseadas nos seguintes direitos:
                    </p>
                    <p class="paragraph-forwards">
                      i. Confirmação da existência de tratamento.
                    </p>
                    <p class="paragraph-forwards">
                      ii. Acesso aos dados.
                    </p>
                    <p class="paragraph-forwards">
                      iii. Correção de dados incompletos, inexatos ou
                      desatualizados.
                    </p>
                    <p class="paragraph-forwards">
                      iv. Anonimização, bloqueio ou eliminação de dados
                      desnecessários, excessivos ou tratados em desconformidade
                      com a lei.
                    </p>
                    <p class="paragraph-forwards">
                      v. Portabilidade dos dados a outro fornecedor de serviço
                      ou produto, mediante requisição expressa pelo usuário.
                    </p>
                    <p class="paragraph-forwards">
                      vi. Eliminação dos dados tratados com consentimento do
                      usuário.
                    </p>
                    <p class="paragraph-forwards">
                      vii. Obtenção de informações sobre as entidades públicas
                      ou privadas com as quais a Educatena compartilhou os seus
                      dados.
                    </p>
                    <p class="paragraph-forwards">
                      viii. Informação sobre a possibilidade de o usuário não
                      fornecer o consentimento, bem como de ser informado sobre
                      as consequências em caso de negativa.
                    </p>
                    <p class="paragraph-forwards">
                      ix. Revogação do consentimento.
                    </p>
                    <p>
                      Parte dos direitos acima expostos poderá ser exercida
                      diretamente por você, a partir da gestão de informações de
                      cadastro, no site da Educatena na internet
                      <a
                        href="https://educatena.com.br"
                        class="hover-style-link"
                        >(www.totalprepexam.com)</a
                      >, enquanto outra parte dependerá do envio de requisição
                      para nosso encarregado de proteção de dados, por meio do
                      e-mail
                      <a
                        href="mailto:tic@totalprepexam.com"
                        class="hover-style-link"
                        >tic@totalprepexam.com</a
                      >, para posterior avaliação e adoção das providências
                      necessárias.
                    </p>
                    <p>
                      O usuário fica ciente, por meio da leitura deste
                      documento, que a eventual solicitação de exclusão de
                      informações essenciais para a gestão de seu cadastro junto
                      à Educatena implicará o término de sua relação contratual,
                      com o consequente cancelamento dos serviços então
                      prestados.
                    </p>
                    <p>
                      A Educatena empreenderá todos os esforços razoáveis para
                      atender às requisições feitas por usuários no menor tempo
                      possível. No entanto, fatores justificáveis, tais como a
                      complexidade da ação requisitada, poderão atrasar ou
                      impedir o seu rápido atendimento, sendo certo que, em caso
                      de demora, a Educatena apresentará ao usuário os devidos
                      motivos.
                    </p>
                    <p>
                      Por fim, o usuário deve estar ciente de que a sua
                      requisição poderá ser legalmente rejeitada, seja por
                      motivos formais (a exemplo de sua incapacidade de
                      comprovar sua identidade) ou legais (a exemplo do pedido
                      de exclusão de dados cuja manutenção é livre exercício de
                      direito pela Educatena), sendo certo que, na hipótese de
                      impossibilidade de atendimento a essas requisições, a
                      Educatena apresentará ao usuário as justificativas
                      razoáveis.
                    </p>
                    <h5 class="post-title font-weight--light">
                      VIII. Legislação e foro
                    </h5>
                    <p>
                      Esta regra será interpretada segundo a legislação
                      brasileira, no idioma português, especialmente a Lei
                      13.709/18, sendo eleito o Foro Central da Comarca de
                      Brasília no Distrito Federal para dirimir qualquer
                      litígio, questão ou dúvida, com expressa renúncia de
                      qualquer outro, por mais privilegiado que seja.
                    </p>
                    <h5 class="post-title font-weight--light">
                      IX. Definições
                    </h5>
                    <p>
                      Caso tenha alguma dúvida sobre os termos utilizados nesta
                      Regra de Privacidade, sugerimos consultar o glossário
                      abaixo.
                    </p>

                    <div class="row portfolio-details-feature-wrap">
                      <div class="col-lg-12">
                        <div class="portfolio-details-content">
                          <div class="portfolio-details-table mt-30">
                            <table class="mb-0 table-th">
                              <th>Termo</th>
                              <th>Definições</th>
                              <tr class="portfolio-details-tags">
                                <td class="label">Anonimização</td>
                                <td>
                                  Processo por meio do qual o dado perde a
                                  possibilidade de associação, direta ou
                                  indireta, a um indivíduo, considerados os
                                  meios técnicos razoáveis e disponíveis no
                                  momento do tratamento.
                                </td>
                              </tr>
                              <tr class="portfolio-details-tags">
                                <td class="label">Cookies</td>
                                <td>
                                  Arquivos enviados pelo servidor do site para o
                                  computador dos usuários, com a finalidade de
                                  identificá-lo e obter os dados de acesso, como
                                  páginas navegadas ou links clicados,
                                  permitindo, desta forma, personalizar a
                                  navegação dos usuários no site, de acordo com
                                  o seu perfil.
                                </td>
                              </tr>
                              <tr class="portfolio-details-tags">
                                <td class="label">Dado pessoal</td>
                                <td>
                                  Qualquer informação relacionada a pessoa
                                  natural, direta ou indiretamente, identificada
                                  ou identificável.
                                </td>
                              </tr>
                              <tr class="portfolio-details-tags">
                                <td class="label">Dado pessoal sensível</td>
                                <td>
                                  Categoria especial de dados pessoais
                                  referentes à origem racial ou étnica,
                                  convicção religiosa, opinião, regra, filiação
                                  a sindicato ou a organização de carácter
                                  religioso, filosófico ou político, referentes
                                  à saúde ou à vida sexual, dados genéticos ou
                                  biométricos relativos a pessoa natural.
                                </td>
                              </tr>
                              <tr class="portfolio-details-tags">
                                <td class="label">IP</td>
                                <td>
                                  Abreviatura de Internet Protocol. É um
                                  conjunto de números que identifica o
                                  computador dos usuários na internet.
                                </td>
                              </tr>
                              <tr class="portfolio-details-tags">
                                <td class="label">Logs</td>
                                <td>
                                  Registros de atividades dos usuários efetuadas
                                  no site.
                                </td>
                              </tr>
                              <tr class="portfolio-details-tags">
                                <td class="label">Site</td>
                                <td>
                                  Designa o endereço eletrônico
                                  <a
                                    href="https://educatena.com.br"
                                    class="hover-style-link"
                                    >https://educatena.com.br</a
                                  >
                                  e seus subdomínios
                                </td>
                              </tr>
                              <tr class="portfolio-details-tags">
                                <td class="label">Usuário</td>
                                <td>
                                  Pessoa natural a quem se referem os dados
                                  pessoais, tais como antigos, presentes ou
                                  potenciais clientes, colaboradores,
                                  contratados, parceiros comerciais e terceiros.
                                </td>
                              </tr>
                              <tr class="portfolio-details-tags">
                                <td class="label">Tratamento</td>
                                <td>
                                  Toda operação realizada com dados pessoais,
                                  como as que se referem a coleta, produção,
                                  recepção, classificação, utilização, acesso,
                                  reprodução, transmissão, distribuição,
                                  processamento, arquivamento, armazenamento,
                                  eliminação,
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p></p>
                    <p>
                      Sempre que a Educatena entender necessário, a Política de
                      Privacidade poderá sofrer alterações que serão publicadas
                      em nossos sites e aplicativos. Essas alterações serão
                      válidas, eficazes e vinculantes após a nova versão ser
                      divulgada ou depois de serem comunicadas de qualquer outra
                      forma a você.
                    </p>
                    <p>
                      Os Termos e Condições de Privacidade aqui descritos são
                      interpretados segundo a legislação brasileira, no idioma
                      português, sendo eleito o Foro Central da Comarca de
                      Brasília no Distrito Federal para dirimir qualquer
                      litígio, questão ou dúvida, com expressa renúncia de
                      qualquer outro, por mais privilegiado que seja.
                    </p>
                    <p>Atualizada em 03 de janeiro de 2021</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PrivacyPolicy',
  components: {
  },
  data() {
    return {
      load: false,
      limitPosition: 200,
      scrolled: false,
      lastPosition: 500,
    }
  },
  mounted() {
    this.$ga.page(this.$router.history.current.path);
     
    (function() {
      // eslint-disable-next-line no-use-before-define
      scrollTo()
    })()

    function scrollTo() {
      const links = document.querySelectorAll('.scroll > a')
      links.forEach((each) => (each.onclick = scrollAnchors))
    }

    function scrollAnchors(e, respond = null) {
      const distanceToTop = (el) => Math.floor(el.getBoundingClientRect().top)
      e.preventDefault()
      const targetID = respond
        ? respond.getAttribute('href')
        : this.getAttribute('href')
      const targetAnchor = document.querySelector(targetID)
      if (!targetAnchor) return
      const originalTop = distanceToTop(targetAnchor)
      window.scrollBy({ top: originalTop, left: 0, behavior: 'smooth' })
      const checkIfDone = setInterval(function() {
        const atBottom =
          window.innerHeight + window.pageYOffset >=
          document.body.offsetHeight - 190
        if (distanceToTop(targetAnchor) === 0 || atBottom) {
          targetAnchor.tabIndex = '-1'
          targetAnchor.focus()
          clearInterval(checkIfDone)
        }
      }, 800)
    }
  },
  methods: {
    // sticky menu script
    handleScroll() {
      if (
        this.lastPosition < window.scrollY &&
        this.limitPosition < window.scrollY
      ) {
        this.scrolled = true
        // move up!
      }
      if (this.lastPosition > window.scrollY) {
        this.scrolled = true
        // move down
      }
      this.lastPosition = window.scrollY
      this.scrolled = window.scrollY > 50
    },

    // offcanvas searchbox
    toggleClass(addRemoveClass, className) {
      const el = document.querySelector('#search-overlay')
      if (addRemoveClass === 'addClass') {
        el.classList.add(className)
      } else {
        el.classList.remove(className)
      }
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>
<style scoped>
p {
  text-align: justify;
}
.paragraph-forwards {
  padding-left: 8%;
}
.portfolio-details-table td:last-child {
  text-align: justify;
  padding-right: 10px;
}
.portfolio-details-table td:first-child {
  padding-left: 10px;
}
.label {
  text-transform: none;
}
.table-th th {
  text-align: center;
}
.portfolio-details-table table {
  border: 1px solid #ededed;
}
</style>
